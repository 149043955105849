@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('./fonts/fonts.css');

/* @font-face {
  font-family: "EuclidCircular";
  src: local('EuclidCircular'),
    url('./fonts/EuclidCircular/EuclidCircularA-Medium.ttf') format('ttf'),
} */
/* @font-face {
  font-family: "Visby";
  src: local('Visby'),
    url('./fonts/Visby-CF/Fontspring-DEMO-visbycf-regular.otf') format('otf'),
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.paswordInput {
  border: 1px solid black;
}


.footerLink {
  color: #FFF;
  font-family: 'Urbanist', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: uppercase;
  padding: 30px 40px;
  cursor: pointer;
}

.social-icon-box {
  display: inline-block;
}

.social-icons {
  height: 60px !important;
  margin: 10px;
  width: 60px !important;
}

.footer-links-heading {
  color: #1C1D1D !important;
  font-family: 'Inter', sans-serif;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 17.6px !important;
}

.footer-headings {
  color: #1C1D1D;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.6px;
}

.footer-input {
  position: relative;
}

Footer {
  background-color: #0E0E0E !important;
  padding: 0 !important;
}

.bottom-footer {
  border-top: 1px solid #808080;
  margin-top: 22px;
}

.bottom-footer h6 {
  color: #FFF;
  font-family: 'Urbanist', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.googleSuggestion{
  font-family: 'Urbanist', sans-serif;
  font-size: 14px;
  padding: 10px;
  text-align: left;
}



.footer-input input {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #000;
  border-radius: 0;

  color: #1C1D1D;
  font-family: 'Inter', sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-input button {
  background-color: transparent;
  color: #000;
  position: absolute;
  right: 0;
  box-shadow: none;
  bottom: 0;
}

.login-link {
  color: #F0EDE2;
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.logout-icn {
  color: #fff;
}

.footer-input button:hover {
  background-color: transparent !important;
  color: #000 !important;
  box-shadow: none;
}

.header-menu {
  font-family: 'Poppins', sans-serif !important;
  color: white;
  font-size: 16px !important;
}

.header-menu .ant-menu-item {
  padding: 0px 30px !important;
  height: auto !important;
}

.header-menu .ant-menu-title-content:after {
  color: white !important;
}

.header-menu .ant-menu-item * {
  font-family: 'Euclid Circular A';
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.signin-link {
  font-family: 'Euclid Circular A';
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
}

.header-find-box {
  gap: 20px;
}

.header-menu .ant-menu-item-selected {
  color: white !important;
}


.header-menu-mobile {
  font-family: 'Poppins', sans-serif !important;
  color: white;
  font-size: 16px !important;
}

.header-menu-mobile .ant-menu-item {
  padding: 10px 20px !important;
  height: auto !important;
}

.ant-menu-horizontal {
  border-bottom: 0 !important;
  justify-content: center;
}


.heroSection {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.header-row {
  align-items: center;
}

.banner-section {
  padding: 30px 0;
  position: relative;
}

.banner-section::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1%;
  background: #fff;
  bottom: 0;
  left: 0;
  box-shadow: -1px -43px 40px 55px #fff;
  z-index: 1;
}


.how-work-section {
  background-color: #F8F8F8;
  padding: 60px 0;
}

.heading-box h6 {
  color: #000;
  font-family: 'Euclid Circular A';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.heading-box h4 {
  color: #000;
  text-align: center;
  font-family: 'Super Comic';
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.circle-cards {
  max-width: 380px;
  height: 380px;
  flex-shrink: 0;
  border-radius: 190px;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #B8E7F3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  margin: 20px;
}

.circle-cards1-colour {
  box-shadow: -60px -38px 60px -25px #b8e7f3ad;
}

.circle-cards2-colour {
  box-shadow: -60px -38px 60px -25px #ffd4d4ab;
}

.circle-cards3-colour {
  box-shadow: -60px -38px 60px -25px #f9dfa6af;
}

.circle-cards h5 {
  color: #000;
  text-align: center;
  font-family: 'Super Comic';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.circle-cards p {
  color: #000;
  text-align: center;
  font-family: 'Euclid Circular A';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 160% */
  text-transform: capitalize;
  margin: 0;
}

.ant-layout-header {
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.04);
}

/*============================ home page start =========================================*/
.web-btn {
  border-radius: 10px;
  background: linear-gradient(180deg, #F00 0%, #940000 100%);
  height: 48px;
  font-family: 'Euclid Circular A';
  text-transform: uppercase;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.6s;
  min-width: 150px;
}

.web-btn:hover {
  color: #940000 !important;
  background: transparent !important;
  border-color: #940000 !important;
}


.ant-modal-footer .ant-btn-primary {
  border-radius: 10px;
  background: linear-gradient(180deg, #F00 0%, #940000 100%);
  height: 48px!important;
  border:none !important;
  font-family: 'Euclid Circular A';
  text-transform: uppercase;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.6s;
  min-width: 150px;
}

.ant-modal-footer .ant-btn-primary:hover {
  color: #940000 !important;
  background: transparent !important;
  border: 2px solid #940000 !important;
}


.ant-modal-footer .ant-btn-default {
  border-radius: 10px;
  background: transparent !important;
  border: 2px solid #940000 !important;

  height: 48px!important;
  font-family: 'Euclid Circular A';
  text-transform: uppercase;
  color: #940000 !important;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.6s;
  min-width: 150px;
}

.ant-modal-footer .ant-btn-default:hover {
  color: #FFF!important;
  background: linear-gradient(180deg, #F00 0%, #940000 100%)!important;
  border: 2px solid #940000 !important;
}

.web-btn-trsp {
  border-radius: 10px;
  background: transparent;
  height: 48px;
  font-family: 'Euclid Circular A';
  text-transform: uppercase;
  color: red;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.6s;
  min-width: 150px;
  border-color: red !important;
}

.web-btn-trsp:hover {
  color: #fff !important;
  background: linear-gradient(180deg, #F00 0%, #940000 100%);
  border-color: rgb(252, 17, 17) !important;
}





.servicebox {
  background-color: #fff;
  padding: 70px 0px 70px 70px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}

.service-h4 {
  color: #000;
  font-family: 'Euclid Circular A';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
  max-width: 400px;
}

.service-h2 {
  color: #000;
  font-family: 'Super Comic';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.service-p {
  color: #434343;
  font-family: 'Euclid Circular A';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
  width: 51px !important;
  height: 14px !important;
  border-radius: 7px;
  background-color: #F00 !important;
}

.alice-carousel__dots-item:not(.__custom) {
  background-color: #818181 !important;
  width: 14px !important;
  height: 14px !important;
}

.download-section {
  background-color: #fff;
}

.download-content h2 {
  color: #000;
  font-family: 'Super Comic';
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  text-transform: uppercase;
  margin: 0;
}

.download-content h2 span {
  color: #ED1C24;
  font-family: 'Caramello Free';
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  text-transform: capitalize;
}

.download-content small {
  color: #000;
  font-family: 'Euclid Circular A';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 6px;
  text-transform: uppercase;
  padding-bottom: 20px;
}

.make-proud {
  background-image: url(../src/assets/map.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  margin: 4rem 0;

}

.proud-card {
  border-radius: 38px;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #B8E7F3;
  box-shadow: 0px 4px 250px 0px rgba(0, 0, 0, 0.10);
  padding: 34px 30px;
  margin: 0 10px;
}

.proud-card h6 {
  color: #000;
  font-family: 'Super Comic';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.proud-card p {
  color: #7A7A7A;
  font-family: 'Euclid Circular A';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.media-name {
  color: #F00;
  font-family: 'Euclid Circular A';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 175% */
}

.service-provider-box h6 {
  color: #000;
  font-family: 'Euclid Circular A';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.service-provider-box h3 {
  color: #000;
  font-family: 'Super Comic';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.service-provider-box p {
  color: #434343;
  font-family: 'Euclid Circular A';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  /* 225% */
}

.service-provider-box {
  /* background-color: #F8F8F8; */
  background-image: url(../src/assets/round-bg2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.trustpeople-box {
  background-color: #F8F8F8;
  padding: 80px 0;
}

.yellow-box {
  /* background-color: #F9DFA6; */
  max-width: 300px;
  height: 300px;
  flex-shrink: 0;
  border-radius: 61px;
  background: linear-gradient(0deg, #F9DFA6 0%, #F9DFA6 100%), #F9DFA6;
  box-shadow: 0px 4px 234px 0px rgba(254, 186, 0, 0.10);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.pink-box {
  /* background-color: #F9DFA6; */
  max-width: 300px;
  height: 300px;
  flex-shrink: 0;
  border-radius: 61px;
  background: linear-gradient(0deg, #F9DBE2 0%, #F9DBE2 100%), #F9DFA6;
  box-shadow: 0px 4px 121px 0px rgba(255, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.blue-box {
  /* background-color: #F9DFA6; */
  max-width: 300px;
  height: 300px;
  flex-shrink: 0;
  border-radius: 61px;
  background: linear-gradient(0deg, #B8E6F1 0%, #B8E6F1 100%), #F9DFA6;
  box-shadow: 0px 4px 188px 0px rgba(54, 208, 244, 0.10);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.inner-white-div {
  width: 222px;
  height: 222px;
  flex-shrink: 0;
  border-radius: 39px;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.inner-white-div h6 {
  color: #000;
  font-family: 'Euclid Circular A';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.inner-white-div h4 {
  color: #000;
  font-family: 'Super Comic';
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.main-banner-box {
  background-image: url(../src/assets/baner.png);
  background-size: cover;
  background-position: top;
  height: 80vh;
}

.main-banner-box h1 {
  color: #000;
  text-align: center;
  font-family: 'Super Comic';
  font-size: 68px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.main-banner-box h3 {
  color: #000;
  font-family: 'Super Comic';
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.main-banner-box h3 span {
  color: red;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.main-banner-box p {
  color: #000;
  font-family: 'Euclid Circular A';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.for-slider-x {
  padding: 0 10px;
}


.step-bg {
  background-image: url(../src/assets/step-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  text-align: center;
}

.step-title {
  color: #000;
  text-align: center;
  font-family: 'Super Comic';
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.step-dec {
  color: #434343;
  text-align: center;
  font-family: 'Euclid Circular A';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  /* 200% */
}

.step-content-box {
  padding-top: 15rem;
}

.step-input {
  position: relative;
  max-width: 755px;
  margin: 0 auto;
}

.step-input .location-icon {
  position: absolute;
  top: 20px;
  bottom: 0;
  margin: 0;
  z-index: 2;
  left: 15px;
  font-size: 20px;
}

.step-input input {
  width: 100%;
  height: 60px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  box-shadow: 0px 9px 17px 0px rgba(0, 0, 0, 0.08);
  padding: 0 50px;
  position: relative;
}


.step-radio-btn .ant-radio-button-wrapper {
  margin: 10px;
  width: 255px;
  height: 55px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #414141;
  text-align: center;
  font-family: 'Euclid Circular A';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

:where(.css-dev-only-do-not-override-1sn0ohr).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: red;
}

:where(.css-dev-only-do-not-override-1sn0ohr).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: red;
}

:where(.css-dev-only-do-not-override-1sn0ohr).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: red !important;
}

:where(.css-dev-only-do-not-override-1sn0ohr).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: red;
  border-color: red;
}

.step-datepicker {
  max-width: 755px;
  margin: 0 auto;

}

.step-datepicker .ant-space {
  width: 100%;
}

.step-datepicker .ant-picker {
  width: 100%;
  height: 60px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  box-shadow: 0px 9px 17px 0px rgba(0, 0, 0, 0.08);
  padding: 0 50px;
}


.filter-headong {
  color: #FFF;
  font-family: 'Super Comic';
  font-size: 18px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 8px 8px 0px 0px;
  background: linear-gradient(180deg, #F00 0%, #940000 100%);
  height: 60px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.filter-headong h4 {
  margin: 0;
}

.inner-filter-box {
  padding: 30px 30px;
}

.left-filter-box {
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF;
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.04);
  margin: 0 18px;
}

.inp {
  border-radius: 10px;
  border: 1px solid #E9E9E9;
  background: #F9F9F9;
  height: 50px;
  padding: 5px 36px;
}

.inner-filter-box Form.Item {
  position: relative;
}

.inner-filter-box .location-icon {
  position: absolute;
  left: 12px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.filter-headings {
  color: #333;
  font-family: 'Euclid Circular A';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  padding: 14px 0;
  margin: 0;
}

.filter-select {
  width: 100%;
}

.inner-filter-box .ant-space-item {
  width: 100%;
}

.apply-btn {
  padding: 10px 0;
  text-align: center;
}

.clear-btn {
  text-align: center;
}

.clear-btn button {
  border: none;
  color: #0E0E0E;
  font-family: 'Euclid Circular A';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: underline;
}

.shop-page {
  padding: 50px 0;
}

.service-heading {
  color: #000;
  text-align: center;
  font-family: 'Super Comic';
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.service-small-heading {
  color: #434343;
  text-align: center;
  font-family: 'Euclid Circular A';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.service-card .ant-card-cover img {
  /* max-height: 277px; */
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}

.service-card {
  margin: 10px 0;
  border: none;
}

.service-card .ant-card-body {
  padding: 14px 0;
  background-color: transparent;
}

.service-card h4 {
  color: #000;
  font-family: 'Super Comic';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-transform: uppercase;
  margin: 0;
}

.service-card p {
  color: #434343;
  font-family: 'Euclid Circular A';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.service-card .card-view-detail a {
  font-family: 'Euclid Circular A';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: rgb(216, 6, 6) !important;
}

.stars {
  color: #FB0;
  font-size: 18px;
}

.inner-filter-box hr {
  margin: 24px 0;
}

.chief-title {
  color: #333;
  font-family: 'Super Comic';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.chief-name {
  color: #000;
  font-family: 'Super Comic';
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.chief-pricehour {
  color: var(--red, #F00);
  font-family: 'Euclid Circular A';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}


.chief-descrip {
  color: #434343;
  font-family: 'Euclid Circular A';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.web-share-btn {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: linear-gradient(180deg, #F00 0%, #940000 100%);
  color: #FFF;
  font-size: 24px;
  transition: 0.6s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


.web-share-btn:hover {
  color: #940000 !important;
  background: transparent !important;
  border-color: #940000 !important;
}

.book-and-share-btn {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chief-location span {
  color: #434343;
  font-family: 'Euclid Circular A';
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding: 10px 30px 0 0;
}


.service-type-box {
  padding: 30px 0;
}

.post-media {
  display: flex;
  align-items: center;
  gap: 10px;
}


.shop-page .alice-carousel__stage-item.__active.__target .ant-image {
  width: 100% !important;
}

.shop-page .alice-carousel__stage-item.__active .ant-image {
  width: 90% !important;
}

.shop-page .alice-carousel__stage-item.__active.__target .ant-image .slider-img {
  margin-top: 0;
}

.shop-page .alice-carousel__stage-item.__active .ant-image .slider-img {
  margin-top: 2rem;
}

.blur-background {
  background-image: url(../src/assets/step-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
}

.blur-bg-inner-card {
  background-color: #fff;
  filter: drop-shadow(0px 4px 47px rgba(0, 0, 0, 0.13));
  border-radius: 30px;
  padding: 40px;
  margin: 50px 0;
}

.blur-bg-inner-card .heading-h2 {
  color: #000;
  font-family: 'Super Comic';
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
  /* text-align: center; */
}

.blur-bg-inner-card-form {
  max-width: 700px;
  margin: 0 auto;
}

.blur-bg-inner-card-form input {
  max-width: 100% !important;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #333;
  padding: 20px;
}

.blur-bg-inner-card-form .ant-picker {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #333;
  padding: 20px;
}

.back-btn {
  color: var(--red, #F00);
  text-align: center;
  font-family: 'Euclid Circular A';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 6px;
}

.ant-modal-footer {
  text-align: center !important;
}

.ant-modal-content {
  padding: 34px !important;
}

.ant-modal-close {
  border: 1px solid #000 !important;
  border-radius: 100px !important;
  color: #000 !important;
}

.swal-text {
  color: #434343;
  text-align: center;
  font-family: 'Euclid Circular A';
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.swal-button--confirm {
  border-radius: 10px;
  background: linear-gradient(180deg, #F00 0%, #940000 100%);
  height: 48px;
  font-family: 'Euclid Circular A';
  text-transform: uppercase;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.6s;
  min-width: 150px;
}

.swal-footer {
  text-align: center;
}

.blur-bg-inner-card-form h6 {
  color: #434343;
  font-family: 'Euclid Circular A';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  padding-bottom: 2rem;
}


.blur-bg-inner-card-form textarea {
  margin: 0 !important;
  border-radius: 5px;
  border: 1px solid #333;
}


.inner-about {
  background-image: none;
}

.ant-space-item {
  width: 100%;
}

.ant-select-selector {
  width: 100% !important;
  height: 50px !important;
  border-radius: 5px !important;
  border: 1px solid #333 !important;
  align-items: center !important;
}

.upload-box {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}

.upload-box button {
  border: none;
  font-size: 24px;
}

.already-login {
  color: #434343;
  font-family: 'Euclid Circular A';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
  text-align: center;
}

.already-login span {
  cursor: pointer;
  color: #F00;
}

.loginas-card {
  max-width: 527px;
  height: 348px;
  background-color: #FFF;
  filter: drop-shadow(0px 4px 47px rgba(0, 0, 0, 0.13));
  border-radius: 30px;
  margin: 0 10px;
  padding: 30px;
}

.loginas-box {
  display: flex;
  align-items: center;
}

.loginas-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loginas-card .icon--size-74 {
  font-size: 74px;
  color: #333;
  ;
}

.loginas-card h4 {
  color: #000;
  text-align: center;
  font-family: 'Super Comic';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.go-icon {
  padding-left: 5px;
  font-size: 24px;
}

.login-card {
  width: 100%;
  background-color: #FFF;
  filter: drop-shadow(0px 4px 47px rgba(0, 0, 0, 0.13));
  padding: 60px;
  border-radius: 30px;
}

.auth-card-title {
  color: #000;
  font-family: 'Super Comic';
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.forget-text {
  color: #000;
  font-family: 'Euclid Circular A';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: end;
}

.auth-card-sub-text {
  color: #434343;
  font-family: 'Euclid Circular A';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  margin: 0;
}

.login-card form {
  padding-top: 20px;
}

.blog-main-img .ant-image {
  width: 100%;
}

.heading-40 {
  color: #000;
  font-family: 'Super Comic';
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.heading-20 {
  color: #000;
  font-family: 'Super Comic';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.descrip-p {
  color: #434343;
  font-family: 'Euclid Circular A';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.feature-post-box {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 12px 0;
}

.for-lg-end {
  text-align: end;
}


.profile-left-box {
  background-color: #fff;
  filter: drop-shadow(0px 4px 60px rgba(0, 0, 0, 0.04));
  min-height: 100vh;
  padding: 20px;
}


.profile-right-box {
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.04);
  padding: 80px;
  margin: 40px 60px;
}

.profile-pic-flex {
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: center;
}

.profile-pic-flex h5 {
  color: #000;
  font-family: 'Euclid Circular A';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.profile-pic-flex p {
  color: #000;
  font-family: 'Euclid Circular A';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.profile-sidebar .ant-menu-item {
  padding-left: 12px !important;
  margin: 10px 0px;
  border-radius: 0;
  color: #333;
  font-family: 'Euclid Circular A';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.profile-sidebar .ant-menu-item-selected {
  background: rgb(255, 143, 143);
  background: linear-gradient(100deg, rgba(255, 143, 143, 1) 0%, rgba(255, 255, 255, 1) 100%);
  color: #F00;
  font-family: 'Euclid Circular A';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.avtr {
  max-width: 300px;
  max-height: 331px;
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.for-row-margin {
  margin: 14px 0;
}

.for-row-margin .ant-form-item {
  margin: 0;
}

.for-row-margin input {
  border: 0;
  border-bottom: 1px solid #D0D0D0;
  border-radius: 0;
}

.for-row-margin .ant-select-selector {
  border: 0 !important;
}

.for-row-margin .ant-form-item-row {
  align-items: center;
}

.for-row-margin .ant-form-item-required {
  width: 150px;
}

.change-pas-btn {
  margin-left: 10px;
}

.for-row-margin .ant-picker {
  border: 0;
  border-bottom: 1px solid #D0D0D0;
  border-radius: 0;
}
.for-detail-flex{
  display: flex;
  align-items: center;
  gap: 5px;
}

.for-detail-flex h5{
  color: #333;
  font-family: 'Euclid Circular A';
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
margin: 0;
}

.order-detail-heading{
  color: #000;
  font-family: 'Euclid Circular A';
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
margin: 0;
}
/*=============================================================================== home page end ===========================================================================*/

/*=============================================================================== table start ===========================================================================*/
.fltr-btn {
  background: #d5af68;
  background: linear-gradient(180deg, #F00 0%, #940000 100%);
  border-radius: 50px;
  width: 50px;
  height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.redSelect * {
  color: red !important;
}

.greenSelect * {
  color: #37ba00 !important;
}

.styledTable tbody tr {
  background-color: #fff !important;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, .16);
  margin: 10px !important;
  width: 100%;
}

.styledTable .ant-table-cell, .styledTable .ant-table-cell:before {
  background: transparent !important;
}

.styledTable .ant-table-cell {
  border: none !important;
}

.styledTable table {
  border-collapse: inherit !important;
  border-spacing: 0 15px !important;
}

.filterDropdown {
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px #00000021;
  max-width: 300px;
}

.ant-popover-inner {
  border: 1px solid rgba(0, 0, 0, .15) !important;
  border-radius: 5px !important;
}

.mainLabel {
  color: #333;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  word-break: break-word;
}

.filterDropdownBody {
  padding: 1rem;
}

.filterInput {
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 100% !important;
}

.search-web-btn {
  border-radius: 10px;
  background: linear-gradient(180deg, #F00 0%, #940000 100%);
  height: 48px;
  font-family: 'Euclid Circular A';
  text-transform: uppercase;
  color: #FFF !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.6s;
  min-width: 150px;
  color: #3c5a92;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input {
  padding: 0 0px 0 10px !important;
}

/*=============================================================================== table end ===========================================================================*/


/*=============================================================================== text sizes start ===========================================================================*/

.profile-lable {
  color: #000;
  font-family: 'Euclid Circular A';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.profile-text {
  color: #434343;
  font-family: 'Euclid Circular A';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.super-26 {
  color: #000;
  font-family: 'Super Comic';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.euclid-20 {
  color: #434343;
  font-family: 'Euclid Circular A';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
}

.text-16 {
  color: #000;
  font-family: 'Super Comic';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.text-40 {
  color: #000;
  font-family: 'Super Comic';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.text-18-super {
  color: #333;
  font-family: 'Super Comic';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0;
}

.text-14 {
  color: #000;
  font-family: 'Euclid Circular A';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

label {
  color: #000 !important;
  font-family: 'Euclid Circular A' !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-transform: capitalize !important;
  padding: 0 !important;
}

.custum-label {
  color: #000;
  font-family: 'Euclid Circular A';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding: 0 !important;
}

/*=============================================================================== text sizes end ===========================================================================*/


/*============================ Responsive start =========================================*/

@media screen and (max-width: 1620px) {
  .yellow-box {
    max-width: 280px;
    height: 280px;
  }

  .pink-box {
    max-width: 280px;
    height: 280px;
  }

  .blue-box {
    max-width: 280px;
    height: 280px;
  }

  .p-text {
    font-size: 14px;
    line-height: 27px;
  }

  .circle-cards {
    max-width: 349px;
    height: 349px;
  }
}

@media screen and (max-width: 1599px) {
  .p-text {
    font-size: 13px;
    line-height: 26px;
    padding: 10px 10px 10px 20px;
  }

  .header-find-box {
    gap: 7px;
  }

  .circle-cards h5 {
    font-size: 14px;
  }

  .circle-cards p {
    font-size: 12px;
    line-height: 18px;
  }

  .circle-cards {
    max-width: 310px;
    height: 310px;
  }


  .yellow-box {
    max-width: 260px;
    height: 260px;
  }

  .pink-box {
    max-width: 260px;
    height: 260px;
  }

  .blue-box {
    max-width: 260px;
    height: 260px;
  }

  .inner-white-div {
    width: 200px;
    height: 200px;
  }

  .provider-content-box {
    padding: 0 30px;
  }

  .service-provider-box p {
    font-size: 14px;
    line-height: 24px;
  }

  .service-provider-box h3 {
    font-size: 24px;
  }

  .web-btn {
    font-size: 14px;
  }

  .web-btn-trsp {
    font-size: 14px;
  }

  .profile-lable {
    color: #000;
    font-family: 'Euclid Circular A';
    font-size: 16px;
  }

  .for-row-margin {
    margin: 10px 0;
  }

  .profile-text {
    color: #434343;
    font-family: 'Euclid Circular A';
    font-size: 14px;
  }

}

@media screen and (max-width: 1399px) {
  .p-text {
    font-size: 12px;
    line-height: 24px;
    padding: 2px 10px 10px 10px;
    margin: 0;
  }

  .big-heading {
    font-size: 300px;
    font-style: normal;
    font-weight: 400;
    line-height: 262px;
  }

  .banner-text {
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
  }

  .heroSection .client-pic {
    width: 70%;
  }

  .text-41 {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
  }

  .text-39-light {
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: 38px;
  }

  .for-max-width p {
    margin-top: 1rem;
  }

  .section-heading {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 64px;
  }

  .web-p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }

  .culturally-heading {
    font-size: 60px;
    letter-spacing: -0.02em;
    line-height: 60px;
    color: #1e1e1e;
    max-width: 650px;
  }

  .header-menu .ant-menu-item {
    padding: 0px 13px !important;
    height: auto !important;
  }

  .header-menu .ant-menu-item * {
    font-family: 'Euclid Circular A';
    font-weight: normal;
    font-size: 14px;
    color: #18181a;
  }

  .header-find-box {
    gap: 7px;
  }

  .web-btn {
    height: 45px;
    font-size: 12px
  }

  .web-btn-trsp {
    height: 45px;
    font-size: 12px
  }

  .main-banner-box h1 {
    font-size: 60px;
  }

  .main-banner-box h3 {
    font-size: 40px;
  }

  .main-banner-box p {
    font-size: 12px;
  }

  .circle-cards {
    max-width: 270px;
    height: 270px;
  }

  .circle-cards h5 {
    font-size: 11px;
  }

  .circle-cards p {
    font-size: 10px;
    line-height: 15px;
  }

  .circle-cards {
    gap: 12px;
  }

  .yellow-box {
    max-width: 220px;
    height: 220px;
  }

  .pink-box {
    max-width: 220px;
    height: 220px;
  }

  .blue-box {
    max-width: 220px;
    height: 220px;
  }

  .inner-white-div {
    width: 160px;
    height: 160px;
  }

  .inner-white-div h6 {
    font-size: 12px;
  }

  .inner-white-div h4 {
    font-size: 26px;
  }

  .for-justify {
    justify-content: space-around;
  }

  .proud-card h6 {
    color: #000;
    font-family: 'Super Comic';
    font-size: 15px;
  }

  .proud-card p {
    font-size: 12px;
    line-height: 20px;
  }

  .media-name {
    font-size: 12px;
  }

  .proud-card {
    padding: 23px 24px;
    box-shadow: 1px 0px 13px 0px rgba(0, 0, 0, 0.10);
  }

  .web-btn {
    font-size: 9px;
  }

  .web-btn-trsp {
    font-size: 9px;
  }

  .text-40 {
    font-size: 28px;
  }

  .text-16 {
    font-size: 14px;
  }

  .text-18-super {
    font-size: 15px;
  }

  .text-14 {
    color: #000;
    font-family: 'Euclid Circular A';
    font-size: 12px;
  }

  .chief-name {
    color: #000;
    font-family: 'Super Comic';
    font-size: 36px;
  }

  .chief-descrip {
    color: #434343;
    font-family: 'Euclid Circular A';
    font-size: 13px;
  }

  .super-26 {
    color: #000;
    font-family: 'Super Comic';
    font-size: 22px;
  }

  .profile-lable {
    color: #000;
    font-family: 'Euclid Circular A';
    font-size: 14px;
  }

  .profile-text {
    color: #434343;
    font-family: 'Euclid Circular A';
    font-size: 13px;
    padding-left: 14px;
  }

  .for-row-margin {
    margin: 8px 0;
  }

}

@media screen and (max-width: 1199px) {
  .abc123 .alice-carousel__wrapper {
    padding: 0px !important;
  }


  .for-logo-end-lg {
    text-align: unset;
  }

  .header-row {
    justify-content: space-between;
  }

  .big-heading {
    font-size: 220px;
    font-style: normal;
    font-weight: 400;
    line-height: 195px;
  }

  .view-product-link {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .view-product-link::before {
    width: 100px;
    height: 100px;
    left: -4rem;
  }

  .footer-input input {
    font-size: 30px;
  }

  .header-menu {
    font-size: 14px !important;
  }

  .login-link {
    font-size: 14px !important;
  }

  .text-41 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 39px;
  }

  .culturally-heading {
    font-size: 50px;
    letter-spacing: -0.02em;
    line-height: 50px;
    color: #1e1e1e;
    max-width: 528px;
  }

  .circle-cards {
    max-width: 230px;
    height: 230px;
  }

  .circle-cards .circle-cards-img {
    width: 60px;
  }

  .main-banner-box h1 {
    font-size: 48px;
  }

  .main-banner-box h3 {
    font-size: 32px;
  }

  .main-banner-box p {
    font-size: 10px;
  }

  .heading-box h4 {
    font-size: 30px;
  }

  .heading-box h6 {
    font-size: 13px;
  }

  .service-h4 {
    font-size: 13px;
  }

  .service-h2 {
    font-size: 24px;
  }

  .service-p {
    font-size: 13px;
    line-height: 26px;
  }

  .download-content h2 {
    font-size: 32px;
    line-height: 44px;
  }

  .download-content small {
    font-size: 10px;
    letter-spacing: 5px;
  }

  .download-content .app-pic {
    width: 200px
  }

  .provider-content-box {
    padding: 0 30px;
  }

  .service-provider-box h3 {
    font-size: 16px;
  }

  .service-provider-box p {
    font-size: 10px;
    line-height: 20px;
  }

  .heading-box h4 {
    font-size: 26px;
  }

  .text-40 {
    font-size: 24px;
  }

  .text-18-super {
    font-size: 11px;
    line-height: 16px;
  }

  .text-14 {
    color: #000;
    font-family: 'Euclid Circular A';
    font-size: 10px;
  }

  .chief-name {
    color: #000;
    font-family: 'Super Comic';
    font-size: 28px;
  }

  .chief-descrip {
    color: #434343;
    font-family: 'Euclid Circular A';
    font-size: 12px;
    line-height: 20px;
  }

  .profile-right-box {
    margin: 28px 10px;
    padding: 26px;
  }

}

@media screen and (max-width: 1050px) {}

@media screen and (max-width: 991px) {
  .paper-pic1 {
    padding-right: 0px;
  }

  .proud-card h6 {
    font-size: 13px;
  }

  .proud-card {
    padding: 18px 22px;
  }

  .blog-main-img {
    margin: 14px 0;
  }

  .for-lg-end {
    text-align: end;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .paper-pic1 {
    text-align: center;
  }

  .center-767 {
    text-align: center;
    margin-bottom: 10px;
  }

  .header-row {
    padding: 0px 10px;
  }

  .culturally-heading {
    max-width: 100%;
    text-align: center;
    padding: 20px 0;
  }

  .p-text {
    margin-top: 1rem;
  }

  .img-container {
    text-align: center;
  }

  .big-heading {
    font-size: 125px;
    font-style: normal;
    font-weight: 400;
    line-height: 116px;
  }

  .banner-text {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 47px;
  }

  .heroSection .client-pic {
    width: 100%;
  }

  .for-top-and-left-mrgn {
    margin-left: 0rem;
    margin-top: 0rem;
  }

  .left-mrgn {
    margin-left: 0rem;
  }

  .text-41 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  .text-39-light {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
  }

  .read-more-btn::before {
    width: 100px;
    height: 100px;
    left: -3rem;
  }

  .section-heading {
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px;
  }

  .camra-icn {
    left: 12px !important;
  }

}

@media screen and (max-width: 575px) {
  .culturally-heading {
    font-size: 30px;
    letter-spacing: -0.02em;
    line-height: 34px;
    color: #1e1e1e;
    max-width: 528px;
    padding: 14px 0px;
  }

  .float-left-pic {
    float: none;
    padding: 0 0 20px 20px;
  }

  .contact-heading {
    font-size: 28px;
    letter-spacing: -0.02em;
    line-height: 30px;
  }

  .contact-box {
    padding: 0px 1rem;
    padding-bottom: 2rem;
  }

  .contact-box .ant-form-item-required {
    font-size: 16px !important;
    letter-spacing: -0.02em;
    line-height: 25px;
  }

  .send-btn {
    text-align: center;
  }

  .contact-link {
    font-size: 16px;
  }

  .social-icons {
    margin: 8px;
    width: 30px !important;
    height: 30px !important;
  }

  .music-card-pic {
    width: 120px !important;
  }

  .footerLink {
    font-family: 'Euclid Circular A';
    font-weight: normal;
    font-size: 12px;
  }

  .main-banner-box {
    height: 46vh;
  }

  .main-banner-box h1 {
    font-size: 20px;
  }

  .main-banner-box h3 {
    font-size: 18px;
  }

  .main-banner-box p {
    font-size: 7px;
  }

  .how-work-section {
    padding: 30px 0;
  }

  .heading-box h4 {
    font-size: 17px;
  }

  .heading-box h6 {
    font-size: 11px;
  }

  .circle-cards {
    margin: 10px auto;
  }

  .servicebox {
    padding: 40px 0px 40px 40px;
  }

  .service-h4 {
    font-size: 12px;
  }

  .service-h2 {
    font-size: 20px;
  }

  .web-btn {
    font-size: 11px;
  }

  .web-btn-trsp {
    font-size: 11px;
  }

  .service-p {
    font-size: 12px;
    line-height: 24px;
  }

  .download-content h2 {
    font-size: 24px;
    line-height: 42px;
  }

  .download-content .app-pic {
    width: 162px;
  }

  .download-content small {
    font-size: 10px;
    letter-spacing: 4px;
  }

  .download-content {
    padding: 20px;
    text-align: center;
  }

  .provider-content-box {
    padding: 30px 10px 0 10px;
  }

  .service-provider-box h3 {
    font-size: 15px;
  }

  .service-provider-box h6 {
    font-size: 13px;
  }

  .service-provider-box p {
    font-size: 11px;
    line-height: 24px;
  }

  .trustpeople-box {
    padding: 10px 0 40px 0;
  }

  .yellow-box {
    margin: 10px auto;
  }

  .pink-box {
    margin: 10px auto;
  }

  .blue-box {
    margin: 10px auto;
  }

  .chief-name {
    color: #000;
    font-family: 'Super Comic';
    font-size: 22px;
  }

  .change-pas-btn {
    margin-left: 0px;
    margin-top: 10px;
  }

  .camra-icn {
    left: 12px !important;
  }

  .for-row-margin .ant-space {
    width: 100%;
  }

  .for-row-margin .ant-picker {
    width: 100%;
  }

}

@media screen and (max-width: 480px) {}

@media screen and (max-width: 380px) {}

body .uwy.userway_p1 .userway_buttons_wrapper {
  top: 80px !important;
}