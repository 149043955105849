



@font-face {
    font-family: 'Super Comic';
    src: url('SuperComic.eot');
    src: url('SuperComic.eot?#iefix') format('embedded-opentype'),
        url('SuperComic.woff2') format('woff2'),
        url('SuperComic.woff') format('woff'),
        url('SuperComic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Caramello Free';
    src: url('CaramelloFree.eot');
    src: url('CaramelloFree.eot?#iefix') format('embedded-opentype'),
        url('CaramelloFree.woff2') format('woff2'),
        url('CaramelloFree.woff') format('woff'),
        url('CaramelloFree.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Caramello Free';
    src: url('CaramelloFree_1.eot');
    src: url('CaramelloFree_1.eot?#iefix') format('embedded-opentype'),
        url('CaramelloFree_1.woff2') format('woff2'),
        url('CaramelloFree_1.woff') format('woff'),
        url('CaramelloFree_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}





@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-MediumItalic.eot');
    src: url('EuclidCircularA-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularA-MediumItalic.woff2') format('woff2'),
        url('EuclidCircularA-MediumItalic.woff') format('woff'),
        url('EuclidCircularA-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-LightItalic.eot');
    src: url('EuclidCircularA-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularA-LightItalic.woff2') format('woff2'),
        url('EuclidCircularA-LightItalic.woff') format('woff'),
        url('EuclidCircularA-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-SemiBoldItalic.eot');
    src: url('EuclidCircularA-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularA-SemiBoldItalic.woff2') format('woff2'),
        url('EuclidCircularA-SemiBoldItalic.woff') format('woff'),
        url('EuclidCircularA-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-Italic.eot');
    src: url('EuclidCircularA-Italic.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularA-Italic.woff2') format('woff2'),
        url('EuclidCircularA-Italic.woff') format('woff'),
        url('EuclidCircularA-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-Bold.eot');
    src: url('EuclidCircularA-Bold.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularA-Bold.woff2') format('woff2'),
        url('EuclidCircularA-Bold.woff') format('woff'),
        url('EuclidCircularA-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-Medium.eot');
    src: url('EuclidCircularA-Medium.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularA-Medium.woff2') format('woff2'),
        url('EuclidCircularA-Medium.woff') format('woff'),
        url('EuclidCircularA-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-Regular.eot');
    src: url('EuclidCircularA-Regular.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularA-Regular.woff2') format('woff2'),
        url('EuclidCircularA-Regular.woff') format('woff'),
        url('EuclidCircularA-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-BoldItalic.eot');
    src: url('EuclidCircularA-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularA-BoldItalic.woff2') format('woff2'),
        url('EuclidCircularA-BoldItalic.woff') format('woff'),
        url('EuclidCircularA-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-Light.eot');
    src: url('EuclidCircularA-Light.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularA-Light.woff2') format('woff2'),
        url('EuclidCircularA-Light.woff') format('woff'),
        url('EuclidCircularA-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('EuclidCircularB-LightItalic.eot');
    src: url('EuclidCircularB-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularB-LightItalic.woff2') format('woff2'),
        url('EuclidCircularB-LightItalic.woff') format('woff'),
        url('EuclidCircularB-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('EuclidCircularB-Light.eot');
    src: url('EuclidCircularB-Light.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularB-Light.woff2') format('woff2'),
        url('EuclidCircularB-Light.woff') format('woff'),
        url('EuclidCircularB-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('EuclidCircularB-BoldItalic.eot');
    src: url('EuclidCircularB-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularB-BoldItalic.woff2') format('woff2'),
        url('EuclidCircularB-BoldItalic.woff') format('woff'),
        url('EuclidCircularB-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('EuclidCircularB-Italic.eot');
    src: url('EuclidCircularB-Italic.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularB-Italic.woff2') format('woff2'),
        url('EuclidCircularB-Italic.woff') format('woff'),
        url('EuclidCircularB-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('EuclidCircularA-SemiBold.eot');
    src: url('EuclidCircularA-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularA-SemiBold.woff2') format('woff2'),
        url('EuclidCircularA-SemiBold.woff') format('woff'),
        url('EuclidCircularA-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('EuclidCircularB-Bold.eot');
    src: url('EuclidCircularB-Bold.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularB-Bold.woff2') format('woff2'),
        url('EuclidCircularB-Bold.woff') format('woff'),
        url('EuclidCircularB-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('EuclidCircularB-Medium.eot');
    src: url('EuclidCircularB-Medium.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularB-Medium.woff2') format('woff2'),
        url('EuclidCircularB-Medium.woff') format('woff'),
        url('EuclidCircularB-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('EuclidCircularB-SemiBold.eot');
    src: url('EuclidCircularB-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularB-SemiBold.woff2') format('woff2'),
        url('EuclidCircularB-SemiBold.woff') format('woff'),
        url('EuclidCircularB-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('EuclidCircularB-SemiBoldItalic.eot');
    src: url('EuclidCircularB-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularB-SemiBoldItalic.woff2') format('woff2'),
        url('EuclidCircularB-SemiBoldItalic.woff') format('woff'),
        url('EuclidCircularB-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('EuclidCircularB-MediumItalic.eot');
    src: url('EuclidCircularB-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularB-MediumItalic.woff2') format('woff2'),
        url('EuclidCircularB-MediumItalic.woff') format('woff'),
        url('EuclidCircularB-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sophie Fancy';
    src: url('SophieFancyRegular.eot');
    src: url('SophieFancyRegular.eot?#iefix') format('embedded-opentype'),
        url('SophieFancyRegular.woff2') format('woff2'),
        url('SophieFancyRegular.woff') format('woff'),
        url('SophieFancyRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular B';
    src: url('EuclidCircularB-Regular.eot');
    src: url('EuclidCircularB-Regular.eot?#iefix') format('embedded-opentype'),
        url('EuclidCircularB-Regular.woff2') format('woff2'),
        url('EuclidCircularB-Regular.woff') format('woff'),
        url('EuclidCircularB-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

